var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[_c('el-form',{attrs:{"model":_vm.form,"label-width":"150px"}},[_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.upBgloadIng),expression:"upBgloadIng"}],attrs:{"label":"上传背景图片","required":""}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://api.etouch.top/store/common.UploadFile/uploadOssImage","data":{
            directory: 'WARRANTY_SEAL'
          },"headers":{
              authorization: _vm.token
          },"show-file-list":false,"list-type":"picture-card","on-success":_vm.handBgUrlSuccess,"before-upload":_vm.beforeBgUpload}},[(_vm.form.background_path)?_c('img',{staticClass:"avatar",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.form.background_path}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploadIng),expression:"uploadIng"}],staticClass:"empty",attrs:{"label":"上传质保单公章"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://api.etouch.top/store/common.UploadFile/uploadOssImage","data":{
            directory: 'WARRANTY_SEAL'
          },"headers":{
              authorization: _vm.token
          },"show-file-list":false,"list-type":"picture-card","on-success":_vm.handleLicenseUrlSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.form.seal_path)?_c('img',{staticClass:"avatar",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.form.seal_path}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('span',{on:{"click":function($event){return _vm.empty(0)}}},[_vm._v("清空")])],1),_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploadIngLogo),expression:"uploadIngLogo"}],staticClass:"empty",attrs:{"label":"上传品牌logo"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://api.etouch.top/store/common.UploadFile/uploadOssImage","data":{
            directory: 'WARRANTY_LOGO'
          },"headers":{
              authorization: _vm.token
          },"show-file-list":false,"list-type":"picture-card","on-success":_vm.handleLogoUrlSuccess,"before-upload":_vm.beforeLogoUpload}},[(_vm.form.logo_path)?_c('img',{staticClass:"avatar",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.form.logo_path}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('span',{on:{"click":function($event){return _vm.empty(1)}}},[_vm._v("清空")])],1),_c('el-form-item',{attrs:{"label":"显示商品数量","required":""}},[_c('el-input-number',{attrs:{"min":2,"max":10,"label":"描述文字"},model:{value:(_vm.form.goods_total),callback:function ($$v) {_vm.$set(_vm.form, "goods_total", $$v)},expression:"form.goods_total"}})],1),_c('el-form-item',{attrs:{"label":"模板内容","required":""}},[_c('el-input',{attrs:{"type":"textarea","show-word-limit":"","rows":10},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"质保单说明"}},[_c('el-input',{attrs:{"type":"textarea","maxlength":500,"show-word-limit":"","rows":10},model:{value:(_vm.form.warranty_notice),callback:function ($$v) {_vm.$set(_vm.form, "warranty_notice", $$v)},expression:"form.warranty_notice"}})],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{staticStyle:{"margin-right":"15px"},attrs:{"type":"danger","loading":_vm.uploadIng},on:{"click":_vm.resetQulity}},[_vm._v("重置")]),_c('el-button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"primary","loading":_vm.uploadIng},on:{"click":_vm.saveQulity}},[_vm._v("保存")])],1)],1)],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("质保单预览效果")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.QulityPreview)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }