<template>
  <div >

    <div style="text-align:center" ref="pTag">
      <div data-v-1748a91b="" style="width: 686px; height: 934px; display: inline-block; text-align: left; margin: 20px; position: relative; color: rgb(0, 62, 138); font-size: 12px;">
        <img data-v-1748a91b="" src="${background_path}" alt="" style="width: 100%;">
        <div data-v-1748a91b="" style="position: absolute; top: 30px; left: 38px; width: 610px; height: 854px;">
          <div data-v-1748a91b="" style="text-align: center;">
            <w:p>${shop_log_div}</w:p>
            <img data-v-1748a91b="" src="${shop_logo}" alt="" style="width: 130px;">
            <w:p>${/shop_log_div}</w:p>
          </div>
          <div data-v-1748a91b="" style="position: relative; color: rgb(0, 0, 0); font-size: 24px; font-weight: 500; font-family: &quot;PingFang SC&quot;; text-align: center; margin-top: 30px;"> 质量保证单 </div>
          <div data-v-1748a91b="" style="color: rgb(58, 96, 142); font-size: 13px; font-family: &quot;PingFang SC&quot;; margin-top: 18px;">
            <div data-v-1748a91b="" style="font-weight: 500; font-size: 12px; width: 160px; display: inline-block;">NO.单号:${order_number}</div>
            <div data-v-1748a91b="" style="font-weight: 500; font-size: 12px; width: 140px; display: inline-block;">日期:${create_time}</div>
            <div data-v-1748a91b="" style="font-weight: 500; font-size: 12px; min-width: 140px; display: inline-block;">客户:${member_name}</div>
            <div data-v-1748a91b="" style="font-weight: 500; font-size: 12px; min-width: 130px; margin-right: 10px; float: right;">电话:${member_phone}</div>
          </div>
          <w:p>${goodsListDiv}</w:p>
          <div data-v-1748a91b="" style="background: rgb(233, 244, 249); border-radius: 4px; padding: 6px 4px; margin-top: 5px; display: flex;">
            <div data-v-1748a91b="" style="width: 90px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">货号</span>
            </div>
            <div data-v-1748a91b="" style="width: 134px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">货品名称</span>
            </div>
            <div data-v-1748a91b="" style="width: 50px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">件数 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(件)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 80px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9);">重量 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(克)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 50px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">单价 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 60px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">工费 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元/克)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 60px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">标签价 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 80px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;"> 实收金额 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元)</span>
              </span>
            </div>
          </div>
          <div data-v-1748a91b="" style="padding: 6px 4px;">
            <w:p data-v-1748a91b="">${goodsList}</w:p>
            <div data-v-1748a91b="" style="display: flex; align-items: center; text-align: left;">
              <div data-v-1748a91b="" style="width: 90px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${goods_number}</span>
              </div>
              <div data-v-1748a91b="" style="width: 134px; display: inline-block; vertical-align: top;">
                <div data-v-1748a91b="" style="width: 28px; height: 28px; display: inline-block; vertical-align: top;">
                  <img data-v-1748a91b="" src="${goods_pic}" alt="" style="width: 24px; height: 24px;">
                </div>
                <div data-v-1748a91b="" style="display: inline-block; width: 90px; vertical-align: top;">
                  <div data-v-1748a91b="" style="font-size: 12px; transform: scale(0.8); white-space: nowrap; transform-origin: 0px 0px;">${goods_name}</div>
                  <div data-v-1748a91b="" style="font-size: 12px; transform: scale(0.8); white-space: nowrap; transform-origin: 0px 0px;">
                    <span data-v-1748a91b="" style="color: rgb(0, 128, 255); float: right;">${tagName}</span>
                  </div>
                </div>
              </div>
              <div data-v-1748a91b="" style="width: 50px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); text-align: center; transform-origin: 0px 0px;">${goods_pcs}</span>
              </div>
              <div data-v-1748a91b="" style="width: 80px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${zbd_goldweight}</span>
              </div>
              <div data-v-1748a91b="" style="width: 50px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${rate}</span>
              </div>
              <div data-v-1748a91b="" style="width: 60px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${sale_cutfee}</span>
              </div>
              <div data-v-1748a91b="" style="width: 60px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${total_price}</span>
              </div>
              <div data-v-1748a91b="" style="width: 80px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${actual_sale_price}</span>
              </div>
            </div>
            <w:p data-v-1748a91b="">${/goodsList}</w:p>
          </div>
          <w:p>${/goodsListDiv}</w:p>
          <w:p>${rawGoodsDiv}</w:p>
          <div data-v-1748a91b="" style="background: rgb(233, 244, 249); border-radius: 4px; padding: 6px 4px; margin-top: 5px; display: flex;">
            <div data-v-1748a91b="" style="width: 90px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">旧料编号</span>
            </div>
            <div data-v-1748a91b="" style="width: 134px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">旧料名称</span>
            </div>
            <div data-v-1748a91b="" style="width: 80px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9);">旧料重 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(克)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 90px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">回收金价 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 90px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">回收工费 <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元/克)</span>
              </span>
            </div>
            <div data-v-1748a91b="" style="width: 90px; display: inline-block;">
              <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.9); transform-origin: 0px 0px;">抵扣金额(元) <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; white-space: nowrap; transform: scale(0.8); -webkit-transform-origin-x: 0px;">(元)</span>
              </span>
            </div>
          </div>
          <div data-v-1748a91b="" style="padding: 6px 4px;">
            <w:p data-v-1748a91b="">${rawGoodsList}</w:p>
            <div data-v-1748a91b="" style="display: flex; align-items: center; text-align: left;">
              <div data-v-1748a91b="" style="width: 90px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${goods_number}</span>
              </div>
              <div data-v-1748a91b="" style="width: 134px; display: inline-block; vertical-align: top;">
                <div data-v-1748a91b="" style="width: 28px; height: 28px; display: inline-block; vertical-align: top;">
                  <img data-v-1748a91b="" src="${goods_pic}" alt="" style="width: 24px; height: 24px;">
                </div>
                <div data-v-1748a91b="" style="display: inline-block; width: 90px; vertical-align: top;">
                  <div data-v-1748a91b="" style="font-size: 12px; transform: scale(0.8); white-space: nowrap; transform-origin: 0px 0px;">${goods_name}</div>
                  <div data-v-1748a91b="" style="font-size: 12px; transform: scale(0.8); white-space: nowrap; transform-origin: 0px 0px;">
                    <span data-v-1748a91b="" style="color: rgb(0, 128, 255); float: right;">${tagName}</span>
                  </div>
                </div>
              </div>
              <div data-v-1748a91b="" style="width: 80px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${zbd_goldweight}</span>
              </div>
              <div data-v-1748a91b="" style="width: 90px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${hs_rate}</span>
              </div>
              <div data-v-1748a91b="" style="width: 90px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${sale_cutfee}</span>
              </div>
              <div data-v-1748a91b="" style="width: 90px; display: inline-block; vertical-align: top;">
                <span data-v-1748a91b="" style="display: inline-block; font-size: 12px; transform: scale(0.8); transform-origin: 0px 0px;">${actual_sale_price}</span>
              </div>
            </div>
            <w:p data-v-1748a91b="">${/rawGoodsList}</w:p>
          </div>
          <w:p>${/rawGoodsDiv}</w:p>
          <div data-v-1748a91b="">
            <div data-v-1748a91b="" style="font-size: 13px; color: rgb(51, 51, 51); font-weight: 500;">备注</div>
            <div data-v-1748a91b="" style="font-size: 11px; color: rgb(153, 153, 153); padding: 5px 6px;"> ${remark} </div>
            <div data-v-1748a91b="" style="text-align: right; margin-top: 20px; position: relative;">
              <div data-v-1748a91b="" style="position: absolute; top: -26px; right: 40px;">
                <w:p>${shop_seal_div}</w:p>
                <img data-v-1748a91b="" src="${shop_seal}" alt="" style="width: 160px;">
                <w:p>${/shop_seal_div}</w:p>
              </div>
            </div>
            <div data-v-1748a91b="" style="text-align: center; border-bottom: 1px solid rgb(196, 196, 196); overflow: hidden; padding-bottom: 6px;">
              <div data-v-1748a91b="" style="float: left; text-align: left; font-size: 12px;">
                <div data-v-1748a91b="">合计大写：${pain_in_amount_dx}</div>
                <div data-v-1748a91b="">地址：${shop_address}</div>
              </div>
              <div data-v-1748a91b="" style="display: inline-block; margin-right: 10px; font-size: 12px;">优惠：${zbd_yh}</div>
              <div data-v-1748a91b="" style="display: inline-block; margin-left: 10px; font-size: 12px;">抹零：${order_ml_amount}</div>
              <div data-v-1748a91b="" style="float: right; display: flex;">
                <div data-v-1748a91b="" style="text-align: left; margin-left: 20px; font-size: 12px;">
                  <div data-v-1748a91b="">${pay_type}</div>
                  <div data-v-1748a91b="">金额小计：￥${paid_in_amount}</div>
                  <div data-v-1748a91b="">联系电话：${shop_phone}</div>
                </div>
              </div>
            </div>
            <div data-v-1748a91b="" style="display: flex; color: rgb(153, 153, 153); font-size: 12px; padding-top: 6px;">
              <div data-v-1748a91b="" style="width: 236px; font-size: 12px;">导购员：${shopemployees}</div>
              <div data-v-1748a91b="" style="width: 216px; font-size: 12px;">收银员：${store_user_name}</div>
              <div data-v-1748a91b="" style="font-size: 12px;height:130px; overflow: hidden;">客户签名：
                <div style="text-align:center">
                  <img style="width: 130px;" src="${customer_sign}" alt="">
                </div>

              </div>
            </div>
            <div data-v-1748a91b="" style="font-size: 13px; color: rgb(51, 51, 51); font-weight: 500; margin-top: 20px; margin-bottom: 8px;">商家说明</div>
            <div data-v-1748a91b="" style="color: rgb(85, 85, 85); font-size: 12px;"> ${shop_remark} </div>
          </div>
        </div>
      </div>
        <!-- <div   style="
        width: 686px;
        height: 934px;
        display:inline-block;
        text-align:left;
        margin:20px;
        position: relative;
        color: rgba(0, 62, 138, 1);
        font-size: 12px;">
        <img src="${background_path}"  style="width:100%"  alt="">
        <div style="position:absolute;top:30px;left:38px;width:610px;height:854px;">
          <div style="text-align:center">
            <img src="${shop_logo}"  style="width:130px;"  alt="">
          </div>
          <div style="position: relative;color: rgba(0,0,0,1);font-size: 24px; font-weight: 500;font-family: PingFang SC;text-align:center;margin-top:30px" >

            质量保证单
          </div>
          <div style="color: rgba(58,96,142,1);font-size: 13px; font-family: PingFang SC;margin-top:18px">
            <div style="font-weight: 500;font-size:12px;width:160px;display:inline-block;">NO.单号:${order_number}</div>
            <div style="font-weight: 500;font-size:12px;width:140px;display:inline-block;">日期:${create_time}</div>
            <div style="font-weight: 500;font-size:12px;min-width:140px;display:inline-block;">客户:${member_name}</div>
            <div style="font-weight: 500;font-size:12px;min-width:130px;margin-right:10px;float:right">电话:${member_phone}</div>
          </div>
          <w:p>${goodsListDiv}</w:p>
          <div style="background: rgba(233,244,249,1);border-radius: 4px;padding:6px 4px;margin-top:5px;display: flex;">
            <div style="width:90px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">货号</span>
            </div>
            <div style="width:134px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">货品名称</span>
            </div>
            <div style="width:50px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">件数
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(件)</span>
              </span>
            </div>
            <div style="width:80px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);">重量
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(克)</span>
              </span>
            </div>
            <div style="width:50px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">单价
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元)</span>
              </span>
            </div>
            <div style="width:60px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">工费
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元/克)</span>

              </span>
            </div>
            <div style="width:60px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">标签价
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元)</span>
              </span>
            </div>
            <div style="width:80px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0"> 实收金额
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元)</span>
              </span>
            </div>
          </div>
          <div style="padding:6px 4px;">
            <w:p>${goodsList}</w:p>
            <div style="display:flex; align-items: center;text-align:left">
              <div style="width:90px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${goods_number}</span>
              </div>
              <div style="width:134px;display:inline-block; vertical-align:top;">
                <div style="width:28px;height:28px;display:inline-block;vertical-align:top">
                  <img src="${goods_pic}" alt="" style="width:24px;height:24px;">
                </div>
                <div style="display:inline-block;width:90px;vertical-align:top">
                  <div style="font-size:12px;-webkit-transform: scale(0.8);white-space: nowrap;transform-origin: 0 0">${goods_name}</div>
                  <div  style="font-size:12px;-webkit-transform: scale(0.8);white-space: nowrap;transform-origin: 0 0">
                    <span style="color:#0080FF;float:right;">${tagName}</span>
                  </div>
                </div>
              </div>
              <div style="width:50px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);text-align:center;transform-origin: 0 0">${goods_pcs}</span>
              </div>
              <div style="width:80px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${zbd_goldweight}</span>
              </div>
              <div style="width:50px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${sale_price}</span>
              </div>
              <div style="width:60px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${sale_cutfee}</span>
              </div>
              <div style="width:60px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${sale_price}</span>
              </div>
              <div style="width:80px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${actual_sale_price}</span>
              </div>
            </div>
            <w:p>${/goodsList}</w:p>
          </div>
          <w:p>${/goodsListDiv}</w:p>
          <w:p>${rawGoodsDiv}</w:p>
          <div style="background: rgba(233,244,249,1);border-radius: 4px;padding:6px 4px;margin-top:5px;display: flex;">
            <div style="width:90px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">旧料编号</span>
            </div>
            <div style="width:134px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">旧料名称</span>
            </div>
            <div style="width:80px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);">旧料重
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(克)</span>
              </span>
            </div>
            <div style="width:90px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">回收金价
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元)</span>
              </span>
            </div>
            <div style="width:90px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">回收工费
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元/克)</span>

              </span>
            </div>
            <div style="width:90px;display:inline-block">
              <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.90);transform-origin: 0 0">抵扣金额(元)
                <span style="display:inline-block;font-size:12px;white-space: nowrap;-webkit-transform: scale(0.80); -webkit-transform-origin-x: 0;">(元)</span>
              </span>
            </div>
          </div>
          <div style="padding:6px 4px;">
            <w:p>${rawGoodsList}</w:p>
              <div style="display:flex; align-items: center;text-align:left">
              <div style="width:90px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${goods_number}</span>
              </div>
              <div style="width:134px;display:inline-block; vertical-align:top;">
                <div style="width:28px;height:28px;display:inline-block;vertical-align:top">
                  <img src="${goods_pic}" alt="" style="width:24px;height:24px;">
                </div>
                <div style="display:inline-block;width:90px;vertical-align:top">
                  <div style="font-size:12px;-webkit-transform: scale(0.8);white-space: nowrap;transform-origin: 0 0">${goods_name}</div>
                  <div  style="font-size:12px;-webkit-transform: scale(0.8);white-space: nowrap;transform-origin: 0 0">
                    <span style="color:#0080FF;float:right;">${tagName}</span>
                  </div>
                </div>
              </div>
              <div style="width:80px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${zbd_goldweight}</span>
              </div>
              <div style="width:90px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${hs_rate}</span>
              </div>
              <div style="width:90px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${sale_cutfee}</span>
              </div>
              <div style="width:90px;display:inline-block;vertical-align:top">
                <span style="display:inline-block;font-size:12px;-webkit-transform: scale(0.80);transform-origin: 0 0">${actual_sale_price}</span>
              </div>
              </div>
            <w:p>${/rawGoodsList}</w:p>
          </div>
          <w:p>${/rawGoodsDiv}</w:p>
          <div>
            <div style="font-size:13px;color:#333333;font-weight: 500;">备注</div>
            <div  style="font-size:11px;color:#999999;padding:5px 6px">
              ${remark}
            </div>
            <div style="text-align:right;margin-top:20px;position: relative;" >
              <div style="position:absolute;top:-26px;right:40px">
                <img src="${shop_seal}" style="width:160px" alt="">
              </div>
            </div>
            <div style="text-align:center;border-bottom:1px solid #C4C4C4;overflow: hidden;padding-bottom:6px;">
              <div style="float:left;text-align:left;font-size:12px">
                <div>合计大写：${pain_in_amount_dx}</div>
                <div>地址：${shop_address}</div>
              </div>
              <div style="display:inline-block;margin-right:10px;font-size:12px">优惠：${zbd_yh}</div>
              <div style="display:inline-block;;margin-left:10px;font-size:12px">抹零：${order_ml_amount}</div>
              <div style="float:right;display:flex">
                <div style="text-align:left;margin-left:20px;font-size:12px">
                  <div>${pay_type}</div>
                  <div>金额小计：￥${paid_in_amount}</div>
                  <div>联系电话：${shop_phone}</div>
                </div>
              </div>
            </div>
            <div style="display:flex;color:#999999;font-size:12px;padding-top:6px">
              <div style="width:236px;font-size:12px">导购员：${shopemployees}</div>
              <div style="width:216px;font-size:12px">收银员：${store_user_name}</div>
              <div  style="font-size:12px">客户签名：</div>
            </div>
            <div style="font-size:13px;color:#333333;font-weight: 500;margin-top:20px;margin-bottom:8px">商家说明</div>
            <div style="color:#555555;font-size:12px">
              ${shop_remark}
            </div>
          </div>

        </div>

      </div> -->
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      txt: [],
    };
  },
  mounted() {
    let html=this.$refs.pTag.innerHTML;
    html=JSON.stringify(html)
    console.log(html)
  },
  props: {
    form: {
      type: Object,
      defalt: {
        seal_path: '',
        warranty_notice: '',
      },
    },
  },
  watch: {
    form: {
      handler(val) {
        const { warranty_notice } = val;
        this.txt = warranty_notice.split(/[\r\b\t\n\f]/g);
      },
    },
  },
};
</script>

<style lang="less" scoped>

.imgs {
  background: url('../../../../assets/images/QualityDocuments/Qualitybg.png');

}
.img {
  background: url('../../../../assets/images/order/quality.png');
}

.logo {
  display:block;
  width:auto;
  height:80px;

}
</style>
