import { post } from '@/utils/request';

const setReq = ({ seal_path, warranty_notice,logo_path}) => post({
  url: '/store/order.Warranty/set',
  data: {
    seal_path,
    logo_path,
    warranty_notice,
  },
});

const getSetReq = () => post({
  url: '/store/order.Warranty/getSet',
});
// 获取质保单初始化参数
const getTemplateSys = (data) => post({
  url: 'store/goods.PrintLocalTemplate/getTemplateSys',
  data
})
// 保存质保单
const saveQulity = (data) => post({
  url: 'store/order.Warranty/set',
  data
})
// 重置质保单
const resetWarranty = (data) => post({
  url: 'store/order.Warranty/resetWarranty',
  data
})


export {
  setReq,
  getSetReq,
  getTemplateSys,
  saveQulity,
  resetWarranty
};
