<template>
  <div class="wrapper">
    <div class="left">
      <el-form :model="form" label-width="150px">
        <el-form-item label="上传背景图片" required v-loading="upBgloadIng">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'WARRANTY_SEAL'
            }"
            :headers="{
                authorization: token
            }"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="handBgUrlSuccess"
            :before-upload="beforeBgUpload"
          >
            <img
              v-if="form.background_path"
              :src="form.background_path"
              style="object-fit: contain;"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传质保单公章"  v-loading="uploadIng" class="empty">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'WARRANTY_SEAL'
            }"
            :headers="{
                authorization: token
            }"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="handleLicenseUrlSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="form.seal_path"
              :src="form.seal_path"
              style="object-fit: contain;"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

          </el-upload>
          <span @click="empty(0)">清空</span>
        </el-form-item>

         <el-form-item label="上传品牌logo"  v-loading="uploadIngLogo" class="empty">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'WARRANTY_LOGO'
            }"
            :headers="{
                authorization: token
            }"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="handleLogoUrlSuccess"
            :before-upload="beforeLogoUpload"
          >
            <img
              v-if="form.logo_path"
              :src="form.logo_path"
              style="object-fit: contain;"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span @click="empty(1)">清空</span>
        </el-form-item>
        <el-form-item label="显示商品数量" required>
          <el-input-number v-model="form.goods_total" :min="2" :max="10" label="描述文字"></el-input-number>
        </el-form-item>

        <el-form-item label="模板内容" required>
          <el-input type="textarea"  show-word-limit :rows="10" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="质保单说明">
          <el-input type="textarea"  :maxlength="500" show-word-limit :rows="10" v-model="form.warranty_notice"></el-input>
        </el-form-item>
        <div style="text-align:center">
          <el-button type="danger" @click="resetQulity" :loading="uploadIng" style="margin-right:15px">重置</el-button>
          <el-button type="primary" @click="saveQulity" :loading="uploadIng" style="margin-left:15px">保存</el-button>
        </div>
      </el-form>
    </div>
    <div class="right">
      <div class="title">质保单预览效果</div>
      <div style="text-align:center">
        <div v-html="QulityPreview"></div>
      </div>
      <!-- <Qulity :form="form" /> -->
    </div>
  </div>
</template>

<script>
import { setReq, getSetReq,getTemplateSys,saveQulity,resetWarranty} from '@/api/order/warranty/config';
import Cookies from 'js-cookie';
import storage from 'good-storage';
import Qulity from './Qulity.vue';

export default {
  data() {
    return {
      token: '',
      form: {
        seal_path: '',//公章图片
        logo_path:"",//logo图片
        background_path:"",//背景图片
        content:"",//模板内容
        warranty_notice:"",//说明内容
        goods_total:0,//商品数量
      },
      uploadIng: false,
      uploadIngLogo:false,
      upBgloadIng:false,//图片上传加载
      QulityPreview:"",//质保单预览
    };
  },
  components: {
    Qulity,
  },
  created() {
    this.token = storage.session.get('token') || Cookies.get('token');
    this.getTemplateSys();
  },
  methods: {//获取质保单初始化参数
    getTemplateSys(){
      getTemplateSys().then((res) => {
        console.log(res)
        this.form={
          seal_path:res.data.template.seal_path,//公章图片
          logo_path:res.data.template.logo_path,//logo图片
          background_path:res.data.template.background_path,//背景图片
          content:res.data.template.content,//模板内容
          warranty_notice:res.data.template.warranty_notice,//说明内容
          goods_total:res.data.template.goods_total,//商品数量
        }
        this.form.warranty_notice=this.TransferString(this.form.warranty_notice,2)

        this.QulityPreview=res.data.data;//预览数据
        console.log(this.form)
        // console.log(res)
      })
    },//保存质保单
    saveQulity(){
      const form = { ...this.form };
      console.log(form)
      this.form.warranty_notice=this.TransferString(this.form.warranty_notice,1)
      if (!form.background_path) {
        this.$message.error('请上传背景图片');
        return;
      }
      if (!form.goods_total) {
        this.$message.error('请填写显示商品数量');
        return;
      }

      saveQulity(this.form).then((res) => {
        console.log(res)
         if (res.code === 1) {
            this.$message.success('更新成功');
            this.getTemplateSys();
          }
      })
    },//重置质保单
    resetQulity(){
      this.$confirm('此操作将重置质保单模板, 请谨慎操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          resetWarranty(this.form).then((res) => {
            if(res.code==1){
              this.$message({
                type: 'success',
                message: '重置成功!'
              });
              this.getTemplateSys();
            }
          })
        })
    },
    // 获取设置
    getSet() {
      getSetReq()
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            if (res.data) {
              this.form = res.data;
            }
          }
        });
    },
    handleSave() {
      const form = { ...this.form };
      if (!form.seal_path) {
        this.$message.error('请上传质保单公章');
        return;
      }
      if (!form.logo_path) {
        this.$message.error('请上品牌logo');
        return;
      }

      console.log(form.warranty_notice.toString());
      setReq({ ...form })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('更新成功');
            this.getSet();
          }
        });
    },
    //换行替换
    TransferString(content,type){
      var string = content;
      if(type==1){
        try{
          string=string.replace(/\r\n/g,"</br>")
          string=string.replace(/\n/g,"</br>");
          string = string.replace(/\s/g,"&nbsp;");
        }catch(e) {

        }
      }else{
        try{
          string=string.replace(/<\/br>/g,"\r\n")
          string=string.replace(/&nbsp;/g," ")
        }catch(e) {

        }
      }
       return string;
    },
    beforeAvatarUpload() {
      this.uploadIng = true;
      console.log(this.uploadIng);
    },//打开背景图上传加载
    beforeLogoUpload(){
      this.uploadIngLogo=true
    },//背景图上传成功处理
    beforeBgUpload(response){
      this.upBgloadIng=true;
    },//清空图片
    empty(type){
      console.log(this.form)
      if(type==0){
        this.form.seal_path='';
        this.saveQulity()
      }else{
        this.form.logo_path='';
        this.saveQulity()
      }
    },
    handBgUrlSuccess(){
      if (response.code === 1) {
        this.upBgloadIng = false;
        const form = { ...this.form };
        form.background_path = response.data;
        this.form = form;
        this.saveQulity()
      } else {upBgloadIng
       this.uploadIng = false;
        this.$message.error(response.msg);
      }
    },

    handleLicenseUrlSuccess(response) {
      if (response.code === 1) {
        this.uploadIng = false;
        const form = { ...this.form };
        form.seal_path = response.data;
        this.form = form;
        this.saveQulity()
      } else {
       this.uploadIng = false;
        this.$message.error(response.msg);
      }
    },

    handleLogoUrlSuccess(response) {
      if (response.code === 1) {
        this.uploadIngLogo = false;
        const form = { ...this.form };
        form.logo_path = response.data;
        this.form = form;
        this.saveQulity()
      } else {
        this.uploadIngLogo = false;
        this.$message.error(response.msg);
      }
    },

  },
};
</script>

<style lang="less" scoped>
.empty{
  .avatar-uploader{
    display: inline-block;
    vertical-align: top;
  }
  span{
    line-height: 160px;
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
    color: red;
    cursor:pointer;
  }
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.wrapper {
  width: 100%;
  // height: calc(100vh - 85px);
  background: white;
  padding: 20px;
  box-sizing: border-box;
  .left {
    margin-top: 43px;
    width: 40%;
    border: 1px solid #ccc;
    padding: 30px 40px 30px 10px;
  }
  .right {
    flex: 1;
    // margin-left: 150px;
    .title {
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
